/**
 * SESSION_EXPIRED error handling:
 * 
 * This error occurs when the user's frontend session is active but the Forgerock session has expired.
 * While user activity (scrolling/clicking) keeps the UI session alive, only API requests refresh the Forgerock session timer.
 * Imagine a scenario where the user's authenticated but they are merely scrolling without making backend calls. 
 * As a result, once the user tries to log-out after Forgerock session expiration, Forgerock returns a 500 on /revokeToken triggering the ServerErrorModal.
 * The user can exit the modal but they cannot log out on the frontend.
 * We handle this edge-case on line 95 in APIMiddleware.js which logs the user out instead of showing the ServerErrorModal.
 */
export default {
    SESSION_EXPIRED: {
        "fault": {
            "faultstring": "Execution of ServiceCallout SC-EndSession failed. Reason: ResponseCode 401 is treated as error",
            "detail": {
                "errorcode": "steps.servicecallout.ExecutionFailed"
            }
        }
    }
}